<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">Williamson Synthesis - Ether Formation</h2>
      <p class="pl-8 mb-5">
        <v-img src="/img/assignments/williamson-synthesis.png" />
      </p>
      <p class="mb-3">
        At
        <stemble-latex content="$\text{0}^\circ\text{C,}$" />
        <number-value :value="volAlkyne" unit="\text{mL}" />
        of 4-butyn-1-ol was slowly added to a stirred suspension of
        <number-value :value="massNaH" unit="\text{g}" />
        of sodium hydride in
        <stemble-latex content="$\text{400 mL}$" />
        of THF. Once the addition was complete, the ice bath was removed and the mixture was stirred
        for 1 hour. A solution containing
        <number-value :value="massBromide" unit="\text{g}" />
        of para-methoxybenzyl bromide in
        <stemble-latex content="$\text{50 mL}$" />
        of THF was then added via cannula. The reaction was stirred overnight then quenched
        (stopped) by the addition of
        <stemble-latex content="100 mL" />
        of saturated
        <stemble-latex content="$\ce{NH4Cl(aq)}\text{.}$" />
      </p>

      <p class="mb-2">
        The organic and aqueous layers were then separated and the product was extracted using 3-100
        mL portions of ethyl ether. The combined layers were washed once with 50 mL of saturated
        <stemble-latex content="$\ce{NaCl(aq)}$" />
        and then dried over 5 g of
        <stemble-latex content="$\ce{MgSO4}\text{.}$" />
        The product was purified by column chromatography yielding
        <number-value :value="massProduct" unit="\text{g}" />
        of a pale yellow oil.
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'PercentYieldLab1',
  components: {
    StembleLatex,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  computed: {
    massBromide() {
      return this.taskState.getValueBySymbol('massBromide').content;
    },
    massNaH() {
      return this.taskState.getValueBySymbol('massNaH').content;
    },
    volAlkyne() {
      return this.taskState.getValueBySymbol('volAlkyne').content;
    },
    massProduct() {
      return this.taskState.getValueBySymbol('massProduct').content;
    },
  },
};
</script>
